<template>
  <div>
    <section class="bg-white p-3 rounded shadow-sm">
      <h1>KGD</h1>
      <h3>Konfirmasi Golongan Darah</h3>

      <b-form-group
        class="mt-5 w-100"
        label="Masukkan Barcode"
        label-for="barcode-input"
      >
        <b-form-input
          id="barcode-input"
          v-model="formData.barcode"
          type="text"
          placeholder="Masukkan barcode"
          @input="debouncedCheckingBarcodes"
        />
      </b-form-group>

      <b-table
        responsive
        :items="tableForm.items"
        :fields="tableForm.fields"
        :busy="tableForm.isBusy"
      >
        <template #cell(blood_type)="{ item }">
          {{ item.blood_type.replace("+", " Pos").replace("-", " Neg") }}
        </template>
        <template #cell(conformation_blood_type)="{ item, index }">
          <b-form-select
            v-model="tableForm.items[index].conformation_blood_type"
            :options="bloodTypeOptions"
          />
        </template>
        <template #cell(action)="{ item, index }">
          <b-button variant="danger" @click="tableForm.items.splice(index, 1)"
            ><i class="ri-delete-bin-6-line"
          /></b-button>
        </template>
      </b-table>

      <div class="d-flex justify-content-end">
        <b-button class="mt-3" variant="primary" @click="saveData"
          >Simpan</b-button
        >
      </div>
    </section>

    <section class="bg-white p-3 rounded shadow-sm mt-5">
      <h3>Daftar Konfirmasi Golongan Darah</h3>
      <div class="d-flex align-items-end justify-content-between">
        <p class="mb-0 mr-3">Semua ({{ tableList.totalRows }})</p>
        <b-input-group class="w-50">
          <b-input-group-prepend>
            <b-button
              variant="outline-black-50"
              class="border-right-0 pr-0 pl-2"
            >
              <i class="ri-search-line remix-icon text-primary" />
            </b-button>
          </b-input-group-prepend>
          <b-form-input
            v-model="tableList.searchQuery"
            type="search"
            placeholder="Search"
            class="border-left-0"
            @input="debounceSearch"
          />
        </b-input-group>
      </div>
      <b-table
        class="mt-3"
        responsive
        :items="tableList.items"
        :fields="tableList.fields"
        :busy="tableList.isBusy"
      >
      </b-table>
      <b-pagination
        align="right"
        v-model="tableList.currentPage"
        :total-rows="tableList.totalRows"
        :per-page="tableList.perPage"
        @change="changePage"
      />
    </section>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BTable,
  BPagination,
  BFormSelect,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
} from "bootstrap-vue";
import manageBloodShipmentAPI from "../../../../api/blood_shipment/manageBloodShipmentAPI";
import kgdAPI from "../../../../api/kgd/kgdAPI";
import debounce from "lodash/debounce";

export default {
  name: "ConfirmBloodType",
  components: {
    BFormGroup,
    BFormInput,
    BTable,
    BPagination,
    BFormSelect,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
  },
  data() {
    return {
      formData: {
        barcode: "",
      },
      tableForm: {
        fields: [
          { key: "barcode", label: "Barcode" },
          { key: "blood_bag_number", label: "Nomor Selang" },
          { key: "bag_type", label: "Jenis Kantong" },
          { key: "blood_type", label: "Golongan Darah" },
          {
            key: "conformation_blood_type",
            label: "Konfirmasi Golongan Darah",
          },
          { key: "action", label: "Aksi" },
        ],
        items: [],
        isBusy: false,
      },
      tableList: {
        searchQuery: "",
        fields: [
          { key: "barcode", label: "Barcode" },
          { key: "initial_blood", label: "Gol. Darah Sebelum" },
          { key: "result_blood", label: "Gol. Darah Hasil" },
          { key: "officer_name", label: "Nama Petugas" },
        ],
        items: [],
        isBusy: false,
        currentPage: 1,
        perPage: 10,
        totalRows: 0,
      },
      bloodTypeOptions: [
        { value: "A Pos", text: "A Pos" },
        { value: "A Neg", text: "A Neg" },
        { value: "B Pos", text: "B Pos" },
        { value: "B Neg", text: "B Neg" },
        { value: "AB Pos", text: "AB Pos" },
        { value: "AB Neg", text: "AB Neg" },
        { value: "O Pos", text: "O Pos" },
        { value: "O Neg", text: "O Neg" },
      ],
    };
  },
  created() {
    // Gunakan debounce agar fungsi dijalankan hanya setelah input berhenti selama 500ms
    this.debouncedCheckingBarcodes = debounce(this.checkingBarcodes, 500);
  },
  methods: {
    async checkingBarcodes() {
      if (this.formData.barcode.trim() === "") return;
      try {
        this.tableForm.isBusy = true;
        const { data: response } = await manageBloodShipmentAPI.checkingBarcode(
          {
            barcode: this.formData.barcode,
          }
        );
        if (response && response.data) {
          const existingItem = this.tableForm.items.find(
            (item) => item.barcode === response.data.barcode
          );
          if (existingItem) {
            this.$bvToast.toast("Barcode sudah discan sebelumnya", {
              title: "Cek barcode",
              variant: "warning",
              solid: true,
            });
          } else if (
            this.tableList.items.some(
              (item) => item.barcode === response.data.barcode
            )
          ) {
            this.$bvToast.toast("Barcode sudah ada di dalam daftar", {
              title: "Cek barcode",
              variant: "warning",
              solid: true,
            });
          } else {
            this.$bvToast.toast("Barcode ditemukan", {
              title: "Berhasil cek barcode",
              variant: "success",
              solid: true,
            });
            const initialBloodType = response.data.blood_type
              .replace("+", " Pos")
              .replace("-", " Neg");
            this.tableForm.items.push({
              ...response.data,
              conformation_blood_type: initialBloodType,
            });
          }
        } else {
          this.$bvToast.toast("Barcode tidak ditemukan", {
            title: "Cek barcode",
            variant: "danger",
            solid: true,
          });
        }
        this.formData.barcode = "";
      } catch (error) {
        this.$bvToast.toast("Barcode tidak ditemukan", {
          title: "Cek barcode",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.tableForm.isBusy = false;
      }
    },

    changePage(page) {
      this.tableList.currentPage = page;
      this.getList();
    },

    async saveData() {
      try {
        // Lakukan post ke API untuk menyimpan data
        const payload = {
          bloods: this.tableForm.items.map((item) => ({
            barcode: item.barcode,
            initial_blood: item.blood_type
              .replace("+", " Pos")
              .replace("-", " Neg"),
            result_blood: item.conformation_blood_type,
          })),
        };

        // Panggil API untuk menyimpan data
        await kgdAPI.addKgd(payload);

        // update tableList
        await this.getList();

        // Kosongkan tabel setelah berhasil menyimpan
        this.tableForm.items = [];
        this.$bvToast.toast("Data berhasil disimpan", {
          title: "Success",
          variant: "success",
          solid: true,
        });
      } catch (error) {
        this.$bvToast.toast(
          error.response.data.message ||
            "Terjadi kesalahan saat menyimpan data",
          {
            title: "Gagal menyimpan data",
            variant: "danger",
            solid: true,
          }
        );
      }
    },
    async getList() {
      const { data: response } = await kgdAPI.getAll({
        search: this.tableList.searchQuery,
        page: this.tableList.currentPage,
        per_page: this.tableList.perPage,
      });

      this.tableList.items = response.data.data;
      this.tableList.totalRows = response.data.total;
    },

    debounceSearch: debounce(function () {
      this.getList();
    }),
  },
  mounted() {
    this.getList();
  },
};
</script>
